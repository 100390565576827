body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gray-borderxx {
  border: 1px solid rgb(216, 138, 138);
  margin: 0.25rem;
  padding: 1rem;

}
.black-border {
  border: 1px solid rgb(236, 141, 141);
  margin: 0.25rem;
  padding: 1rem;
  display: inline-block;

}
.slotset {
  
  margin: 0rem;
  padding: 0rem;
  display: inline-block;

}
.black-border-tight {
  border: 1px solid rgb(236, 141, 141);
  margin: 0rem;
  padding: 0rem;
  display: inline-block;

}
.main-box {
  width: fit-content;
}
